import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import styles from "./portfolio.module.scss"
import {graphql} from "gatsby"
import Project from "../components/project"

const PortfolioPage = ({data, location}) => {
  const projects = data.allProject.nodes

  return (
    <Layout location={location}>
      <SEO title="Home"/>
      <section>
        <Container>
          <h1 style={styles.title}>Portfolio</h1>
          <div className={styles.projects}>
            {projects.map(project => (
              <Project key={project.id} project={project}/>
            ))}
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query {
        allProject {
            nodes {
                id
                name
                slug
                featuredImg {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`

export default PortfolioPage
